<template>
  <institution-sign-in-button @sign-in="ipAddressSignIn" :loading="loading">
    <span>Log in through your Institution</span>
  </institution-sign-in-button>
</template>

<script>
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';

import InstitutionSignInButton from '@/base/elements/social-buttons/InstitutionSignInButton';

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ipAddressSignIn(r) {
      AnalyticsAmplitudeHandler.trackClickLoginThroughInstitutionEvent();
      const url = process.env.VUE_APP_LIBRARY_SITE_BASE + '?help-login=true';
      window.open(url, '_blank').focus();
    },
  },
  components: {InstitutionSignInButton},
};
</script>

<style lang="scss" scoped>
.facebook-sign-in-button-container {
  background-color: #4267b2;
  color: white;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 10px;

  .text {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
  }
}
</style>
