<template>
  <div class="with-sticky-buttons subscription-cancel-confirm">
    <div class="heading">
      <div class="text-lg bold">We're sorry to see you go!</div>
      <close-icon class="close-icon" @click="cancelClick"></close-icon>
    </div>
    <div class="content">
      <div class="text-lg bold neutral">Could you let us know why you are cancelling?</div>

      <radio-select
        :options="reasonOptions"
        :selected="defaultReasonId"
        name="reason-radio"
        @change="reasonChange"
      ></radio-select>

      <div class="comments">
        <textarea
          id="comments"
          v-model="comment"
          class="input"
          rows="3"
          placeholder="Anything you want to share with us? (Optional)"
        ></textarea>
      </div>
    </div>
    <div class="action-buttons vertical">
      <div v-if="subscription.trial_ends_on" class="bold">Cancelling now will end your membership immediately.</div>
      <mcr-button :loading="loading" class="confirm-button" @click="okClick">{{ confirmLabel }}</mcr-button>
      <a @click="cancelClick">Nevermind, I don't want to cancel</a>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import RadioSelect from '@common/elements/filters/radioSelect.vue';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import CloseIcon from 'vue-material-design-icons/Close';

export default {
  props: {
    subscription: Object,
  },
  data() {
    return {
      loading: false,
      reasonOptions: [
        {text: 'Just cancel my subscription', id: 'default'},
        {text: 'Not enough relevant records', id: 'records'},
        {text: 'Too expensive', id: 'price'},
        {text: 'Not sure how to use', id: 'usage'},
        {text: 'Other platforms better suit my needs', id: 'competitor'},
        {text: 'Missing features I need (please explain below)', id: 'features'},
        {text: 'Other (please explain below)', id: 'other'},
      ],
      defaultReasonId: 'default',
      reasonId: 'default',
      comment: '',
    };
  },
  computed: {
    confirmLabel() {
      return 'Cancel Membership';
    },
    survey() {
      const reason = this.reasonOptions.find(r => r.id === this.reasonId);
      return {
        reason: reason ? reason.text : 'Just cancel my subscription',
        comment: this.comment,
        subscription_id: this.subscription.id,
      };
    },
    basicPlanName() {
      const plan = this.$store.getters.subscriptionPlansState.find(plan => plan.is_basic_plan);
      return plan ? plan.display_text : 'Basic';
    },
    standardPlanName() {
      return this.subscription.plan.display_text;
    },
  },
  methods: {
    reasonChange(value) {
      this.reasonId = value;
    },
    cancelClick() {
      if (this.loading) {
        return;
      }
      this.$emit('close');
    },
    okClick() {
      this.loading = true;
      AnalyticsAmplitudeHandler.trackStandardSubscriptionCancelSubmit(this.survey.reason, !!this.survey.comment);
      this.$store
        .dispatch('cancelSubscriptionAction', {id: this.subscription.id, survey: this.survey})
        .then(response => {
          this.cancelRequestedHandler(
            response.is_deactivated,
            Boolean(this.comment || this.reasonId !== this.defaultReasonId)
          );
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancelRequestedHandler(deactivated, feedback) {
      if (deactivated) {
        this.$store.commit('setUserIsSubscribedState', false);
      }
      const query = {
        expire_on: this.subscription.expire_on,
        deactivated: deactivated ? 'true' : undefined,
        feedback: feedback ? 'true' : undefined,
        bp: this.basicPlanName,
        sp: this.standardPlanName,
      };
      this.$router.push({name: 'subscription-canceled', query: query});
    },
  },
  components: {RadioSelect, CloseIcon, McrButton},
};
</script>

<style lang="scss" scoped>
.subscription-cancel-confirm.with-sticky-buttons {
  .content {
    .neutral {
      color: $neutral-600;
      margin-bottom: 16px;
    }

    .comments {
      margin-top: 20px;
      display: flex;

      textarea {
        width: 100%;
      }
    }
  }

  .confirm-button {
    min-width: 185px;
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    .content {
      min-width: 500px;
    }
  }
}
</style>
