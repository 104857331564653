<template>
  <div class="subscription-cancel-button" @click="openCancelModal">
    <slot>
      <a>Cancel Plan</a>
    </slot>
  </div>
</template>

<script>
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';

import SubscriptionCancelConfirm from './SubscriptionCancelConfirm';

export default {
  props: {
    subscription: Object,
  },
  methods: {
    openCancelModal() {
      AnalyticsAmplitudeHandler.trackCancelStandardSubscriptionClick(getRoutePageName(this.$route));
      let modalParams = {
        classes: 'clear_modal white_modal',
        name: 'subscription-cancel-confirm',
        height: 'auto',
        scrollable: true,
      };
      const props = {subscription: this.subscription};
      this.$modal.show(SubscriptionCancelConfirm, props, modalParams);
    },
  },
  name: 'SubscriptionCancelButton',
};
</script>

<style lang="scss" scoped></style>
